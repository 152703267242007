// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-articles-bare-land-condo-vs-conventional-condo-jsx": () => import("./../../../src/pages/articles/bare-land-condo-vs-conventional-condo.jsx" /* webpackChunkName: "component---src-pages-articles-bare-land-condo-vs-conventional-condo-jsx" */),
  "component---src-pages-articles-calgary-rpr-window-wells-air-conditioning-jsx": () => import("./../../../src/pages/articles/calgary-rpr-window-wells-air-conditioning.jsx" /* webpackChunkName: "component---src-pages-articles-calgary-rpr-window-wells-air-conditioning-jsx" */),
  "component---src-pages-articles-certificate-of-title-alberta-jsx": () => import("./../../../src/pages/articles/certificate-of-title-alberta.jsx" /* webpackChunkName: "component---src-pages-articles-certificate-of-title-alberta-jsx" */),
  "component---src-pages-articles-deck-permit-requirements-in-calgary-jsx": () => import("./../../../src/pages/articles/deck-permit-requirements-in-calgary.jsx" /* webpackChunkName: "component---src-pages-articles-deck-permit-requirements-in-calgary-jsx" */),
  "component---src-pages-articles-gas-coop-utility-right-of-ways-rural-properties-jsx": () => import("./../../../src/pages/articles/gas-coop-utility-right-of-ways-rural-properties.jsx" /* webpackChunkName: "component---src-pages-articles-gas-coop-utility-right-of-ways-rural-properties-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("./../../../src/pages/articles/index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-articles-land-surveyors-and-rprs-jsx": () => import("./../../../src/pages/articles/land-surveyors-and-rprs.jsx" /* webpackChunkName: "component---src-pages-articles-land-surveyors-and-rprs-jsx" */),
  "component---src-pages-articles-overland-drainage-right-of-ways-jsx": () => import("./../../../src/pages/articles/overland-drainage-right-of-ways.jsx" /* webpackChunkName: "component---src-pages-articles-overland-drainage-right-of-ways-jsx" */),
  "component---src-pages-articles-relaxation-and-encroachment-info-2022-jsx": () => import("./../../../src/pages/articles/relaxation-and-encroachment-info-2022.jsx" /* webpackChunkName: "component---src-pages-articles-relaxation-and-encroachment-info-2022-jsx" */),
  "component---src-pages-articles-utility-right-of-ways-jsx": () => import("./../../../src/pages/articles/utility-right-of-ways.jsx" /* webpackChunkName: "component---src-pages-articles-utility-right-of-ways-jsx" */),
  "component---src-pages-articles-what-is-a-land-survey-jsx": () => import("./../../../src/pages/articles/what-is-a-land-survey.jsx" /* webpackChunkName: "component---src-pages-articles-what-is-a-land-survey-jsx" */),
  "component---src-pages-articles-what-is-a-plot-plan-jsx": () => import("./../../../src/pages/articles/what-is-a-plot-plan.jsx" /* webpackChunkName: "component---src-pages-articles-what-is-a-plot-plan-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-order-a-development-permit-survey-jsx": () => import("./../../../src/pages/order-a-development-permit-survey.jsx" /* webpackChunkName: "component---src-pages-order-a-development-permit-survey-jsx" */),
  "component---src-pages-order-a-real-property-report-jsx": () => import("./../../../src/pages/order-a-real-property-report.jsx" /* webpackChunkName: "component---src-pages-order-a-real-property-report-jsx" */),
  "component---src-pages-order-placed-fail-jsx": () => import("./../../../src/pages/order-placed-fail.jsx" /* webpackChunkName: "component---src-pages-order-placed-fail-jsx" */),
  "component---src-pages-order-placed-jsx": () => import("./../../../src/pages/order-placed.jsx" /* webpackChunkName: "component---src-pages-order-placed-jsx" */),
  "component---src-pages-policies-terms-conditions-jsx": () => import("./../../../src/pages/policies-terms-conditions.jsx" /* webpackChunkName: "component---src-pages-policies-terms-conditions-jsx" */),
  "component---src-pages-services-construction-services-development-permits-jsx": () => import("./../../../src/pages/services/construction-services/development-permits.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-development-permits-jsx" */),
  "component---src-pages-services-construction-services-engineering-surveys-jsx": () => import("./../../../src/pages/services/construction-services/engineering-surveys.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-engineering-surveys-jsx" */),
  "component---src-pages-services-construction-services-house-stakeouts-jsx": () => import("./../../../src/pages/services/construction-services/house-stakeouts.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-house-stakeouts-jsx" */),
  "component---src-pages-services-construction-services-index-jsx": () => import("./../../../src/pages/services/construction-services/index.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-index-jsx" */),
  "component---src-pages-services-construction-services-large-building-construction-jsx": () => import("./../../../src/pages/services/construction-services/large-building-construction.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-large-building-construction-jsx" */),
  "component---src-pages-services-construction-services-lot-grading-certificates-jsx": () => import("./../../../src/pages/services/construction-services/lot-grading-certificates.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-lot-grading-certificates-jsx" */),
  "component---src-pages-services-construction-services-main-floor-and-footing-elevation-surveys-jsx": () => import("./../../../src/pages/services/construction-services/main-floor-and-footing-elevation-surveys.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-main-floor-and-footing-elevation-surveys-jsx" */),
  "component---src-pages-services-construction-services-plot-plans-jsx": () => import("./../../../src/pages/services/construction-services/plot-plans.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-plot-plans-jsx" */),
  "component---src-pages-services-construction-services-streetscapes-jsx": () => import("./../../../src/pages/services/construction-services/streetscapes.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-streetscapes-jsx" */),
  "component---src-pages-services-construction-services-utility-construction-surveys-jsx": () => import("./../../../src/pages/services/construction-services/utility-construction-surveys.jsx" /* webpackChunkName: "component---src-pages-services-construction-services-utility-construction-surveys-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-legal-surveys-condominium-services-jsx": () => import("./../../../src/pages/services/legal-surveys/condominium-services.jsx" /* webpackChunkName: "component---src-pages-services-legal-surveys-condominium-services-jsx" */),
  "component---src-pages-services-legal-surveys-index-jsx": () => import("./../../../src/pages/services/legal-surveys/index.jsx" /* webpackChunkName: "component---src-pages-services-legal-surveys-index-jsx" */),
  "component---src-pages-services-legal-surveys-lease-area-surveys-jsx": () => import("./../../../src/pages/services/legal-surveys/lease-area-surveys.jsx" /* webpackChunkName: "component---src-pages-services-legal-surveys-lease-area-surveys-jsx" */),
  "component---src-pages-services-legal-surveys-right-of-way-surveys-jsx": () => import("./../../../src/pages/services/legal-surveys/right-of-way-surveys.jsx" /* webpackChunkName: "component---src-pages-services-legal-surveys-right-of-way-surveys-jsx" */),
  "component---src-pages-services-legal-surveys-subdivision-surveys-jsx": () => import("./../../../src/pages/services/legal-surveys/subdivision-surveys.jsx" /* webpackChunkName: "component---src-pages-services-legal-surveys-subdivision-surveys-jsx" */),
  "component---src-pages-services-residential-surveys-compliance-costs-jsx": () => import("./../../../src/pages/services/residential-surveys/compliance-costs.jsx" /* webpackChunkName: "component---src-pages-services-residential-surveys-compliance-costs-jsx" */),
  "component---src-pages-services-residential-surveys-index-jsx": () => import("./../../../src/pages/services/residential-surveys/index.jsx" /* webpackChunkName: "component---src-pages-services-residential-surveys-index-jsx" */),
  "component---src-pages-services-residential-surveys-property-line-surveys-jsx": () => import("./../../../src/pages/services/residential-surveys/property-line-surveys.jsx" /* webpackChunkName: "component---src-pages-services-residential-surveys-property-line-surveys-jsx" */),
  "component---src-pages-services-residential-surveys-real-property-reports-jsx": () => import("./../../../src/pages/services/residential-surveys/real-property-reports.jsx" /* webpackChunkName: "component---src-pages-services-residential-surveys-real-property-reports-jsx" */)
}

